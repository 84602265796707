// script.js

function initCarousels() {
  var carousels = document.querySelectorAll('.carousel');
  for (var i = 0; i < carousels.length; i++) {
    tns({
      container: carousels[i],
      mouseDrag: true,
      nav: false,
    });
  }
}

function main(event) {
  var isInitialLoad = !event; // fires first time on document.ready, not pjax event

  if (isInitialLoad) {
  }

  initCarousels();

  if (window.initMap) {
    initMap();
  }
}

$(function () {
  main(false);
});
