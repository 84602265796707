// from https://css-tricks.com/snippets/jquery/smooth-scrolling/
// modified to use native scrollIntoView when supported

// Select all links with hashes
$('html').on('click', 'a[href*="#"]', function (event) {
  // On-page links
  if (
    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
    location.hostname == this.hostname
  ) {
    // Figure out element to scroll to
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    // Does a scroll target exist?
    if (target.length) {
      // Only prevent default if animation is actually gonna happen
      event.preventDefault();

      // Use native browser smooth scrolling when supported
      if ('scrollBehavior' in document.documentElement.style) {
        target[0].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });

        // Use jquery smooth scrolling if animate is supported
      } else if ('animate' in $()) {
        $('html, body').animate(
          {
            scrollTop: target.offset().top,
          },
          1000
        );

        // Use native browser jump when supported
      } else if ('scrollIntoView' in document.body) {
        target[0].scrollIntoView(true);

        // Use jquery jump worst case
      } else {
        $('html, body').scrollTop(target.offset().top);
      }
    }
  }
});
